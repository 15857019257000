import React, {useEffect, useState} from 'react';

import { Link } from "react-router-dom";
import { Table } from "antd";
import moment from "moment-timezone";
import { useRest } from "../../services/http";

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (
            <Link to={`/view-client/${record.id}`}>{text}</Link>
        ),
    },
    {
        title: 'Data de Nascimento',
        dataIndex: 'birthdate',
        key: 'birthdate',
        render: (text) => moment(text).format("DD/MM/YYYY"),
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Telefone',
        dataIndex: 'phone_number',
        key: 'phone_number',
    }

];

const UpcomingBirthdays = () => {
   
    const { get, loading, data, status } = useRest();
    const [tablePagination, setTablePagination] = useState({
        current: 1,
        pageSize: 15,
    });

    useEffect(() => {
        if (data) {
            setTablePagination({
                ...tablePagination,
                pageSize: data?.per_page || 15,
                total: data?.total || 0,
            });
        }
    }, [data]);

    useEffect(() => {
        get("upcomingBirthdays", {
            page: tablePagination.current,
        }).then((response) => {
            console.log("API Response:", response);
        });
    }, [tablePagination?.current]);
   
    useEffect(() => {
        console.log("upcomingBirthdays Status:", status);
    }, [status]);

    return (
        <Table
            columns={columns}
            dataSource={data?.map((item) => ({
                ...item,
                name: item?.user?.name,
                birthdate: item?.birthdate,
                email: item?.user?.email,
                key: item.id,
            }))}
            pagination={{
                ...tablePagination,
                onChange: (page) => {
                    setTablePagination({
                        ...tablePagination,
                        current: page,
                    });
                },
            }}
            loading={loading}
            rowKey="id"
        />
    );
    
};

export default UpcomingBirthdays;
