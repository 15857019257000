import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Wrapper from "../../components/layout";
import moment from "moment-timezone";
import styled from "./styled.module.scss";
import { useForm } from "antd/es/form/Form";
import { useRest } from "../../services/http";

const Reports = () => {
  const [form] = useForm();
  const [filterParams, setFilterParams] = useState({
    service: null,
    status: null,
    out_of_time: null,
  });
  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]); // Dados originais
  const [columns, setColumns] = useState([]);

  const {
    get: flowsList,
    loading: flowListLoading,
    data: flowsData,
  } = useRest();

  const { get: serviceList, data: serviceData } = useRest();
  const { get: getStatus, data: dataStatus } = useRest();

  useEffect(() => {
    getStatus("statusFlow", {});
    serviceList("serviceList", {});
    flowsList("flowsList", {});
  }, []);

  useEffect(() => {
    if (flowsData?.data?.customers) {
      setOriginalData(flowsData.data.customers); // Salvar os dados originais
      prepareTableData(flowsData.data.customers);
    }
  }, [flowsData]);

  const prepareTableData = (customers) => {
    if (!customers || customers.length === 0) {
      console.warn("Nenhum cliente encontrado após o filtro.");
      setTableData([]);
      return;
    }

    // Filtro para um único serviço baseado no filtroParams.service
    const filteredCustomers = customers
      .map((customer) => ({
        ...customer,
        service: customer.service.filter(
          (service) => service.id === filterParams.service
        ),
      }))
      .filter((customer) => customer.service.length > 0);

    if (filteredCustomers.length === 0) {
      console.warn("Nenhum serviço encontrado para o filtro selecionado.");
      setTableData([]);
      setColumns([]);
      return;
    }

    const rows = [];
    const stepsMap = new Map();

    filteredCustomers.forEach((customer) => {
      customer.service?.forEach((service) => {
        rows.push({
          id: `${customer.id}-${service.id}`,
          customerName: customer.name,
          serviceTitle: service.title,
          stateBon: service.state_bon,
          steps: service.steps,
        });

        // Mapear as etapas (steps) para criar as colunas dinâmicas
        service.steps?.forEach((step) => {
          if (!stepsMap.has(step.title)) {
            stepsMap.set(step.title, step.sort_order || 0);
          }
        });
      });
    });

    // Ordenar as etapas por ordem de execução
    const sortedStepTitles = Array.from(stepsMap.entries())
      .sort((a, b) => a[1] - b[1])
      .map(([title]) => title);

    // Criar colunas dinâmicas apenas para o serviço selecionado
    const dynamicColumns = sortedStepTitles.map((title) => ({
      title,
      dataIndex: title,
      key: title,
      render: (_, record) => {
        const step = record.steps.find((s) => s.title === title);
        if (!step) return "-";

        const { status, start_date, end_date, deadline_date } = step;
        let tagColor = "grey";
        let statusText = "Não iniciado";

        if (status === "FIN") {
          tagColor = "green";
          statusText = moment(end_date).format("DD/MM/YYYY");
        } else if (status === "INP") {
          tagColor = "yellow";
          statusText = moment(start_date).format("DD/MM/YYYY");
        } else if (status === "PEN") {
          tagColor = "orange";
          statusText = moment(deadline_date).format("DD/MM/YYYY");
        }

        if (
          deadline_date &&
          moment(deadline_date).isBefore(moment()) &&
          status !== "FIN"
        ) {
          tagColor = "red";
          statusText = "Fora do prazo";
        }

        return (
          <Tag color={tagColor}>
            <Link to={`/interaction/${step.id}`}>{statusText}</Link>
          </Tag>
        );
      },
    }));

    setTableData(rows);
    setColumns([
      {
        title: "Cliente",
        dataIndex: "customerName",
        key: "customerName",
      },
      {
        title: "Serviço",
        dataIndex: "serviceTitle",
        key: "serviceTitle",
      },
      {
        title: "Estado",
        dataIndex: "stateBon",
        key: "stateBon",
      },
      ...dynamicColumns,
    ]);
  };

  const applyFilters = () => {

    form.validateFields();

    // Se não houver nenhum filtro aplicado, retorna todos os dados
    if (
      !filterParams.service &&
      !filterParams.status &&
      !filterParams.out_of_time
    ) {
      prepareTableData(originalData); // Volta aos dados originais
      return;
    }

    if (!filterParams.service) {
      form.validateFields(["service"]); // Forçar validação do serviço
      return;
    }

    let filteredData = [...originalData];

    // Filtro por serviço (obrigatório)
    filteredData = filteredData
      .map((customer) => ({
        ...customer,
        service: customer.service.filter(
          (service) => service.id === filterParams.service
        ),
      }))
      .filter((customer) => customer.service.length > 0);

    console.log("Após filtro por serviço:", filteredData);

    // Filtro por status (opcional)
    if (filterParams.status) {
      filteredData = filteredData.map((customer) => ({
        ...customer,
        service: customer.service.map((service) => ({
          ...service,
          steps: service.steps.filter(
            (step) => step.status === filterParams.status
          ),
        })),
      }));
    }

    // Filtro por etapas fora do prazo (opcional)
    if (filterParams.out_of_time) {
      filteredData = filteredData.map((customer) => ({
        ...customer,
        service: customer.service.map((service) => ({
          ...service,
          steps: service.steps.filter(
            (step) =>
              step.deadline_date &&
              moment(step.deadline_date).isBefore(moment()) &&
              step.status !== "FIN"
          ),
        })),
      }));
    }

    // Remover serviços sem etapas após os filtros
    filteredData = filteredData
      .map((customer) => ({
        ...customer,
        service: customer.service.filter((service) => service.steps.length > 0),
      }))
      .filter((customer) => customer.service.length > 0);

    prepareTableData(filteredData);
  };
  return (
    <Wrapper>
      <Card className={styled.card}>
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={8}>
            <Form.Item
                label="Serviços"
                name="service"
                rules={[{ required: true, message: "Campo obrigatório" }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Selecionar Serviço"
                  onChange={(value) =>
                    setFilterParams((prev) => ({ ...prev, service: value }))
                  }
                >
                  {serviceData?.data?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Status">
                <Select
                  allowClear
                  showSearch
                  placeholder="Selecionar Status"
                  onChange={(value) =>
                    setFilterParams({ ...filterParams, status: value })
                  }
                >
                  {Object.keys(dataStatus || {}).map((key) => (
                    <Select.Option key={key} value={key}>
                      {dataStatus[key]}
                    </Select.Option>
                  ))}
                </Select>
   
              </Form.Item>
            </Col>
            <Col span={8}>
              <Checkbox
                checked={filterParams.out_of_time}
                onChange={(e) =>
                  setFilterParams({
                    ...filterParams,
                    out_of_time: e.target.checked,
                  })
                }
              >
                Etapas fora do prazo
              </Checkbox>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Button type="primary" onClick={applyFilters}>
                Filtrar
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      {

        // Renderizar a tabela apenas se houver dados

        tableData?.length > 0 &&


        <Card className={styled.card}>
          <Table
            dataSource={tableData}
            columns={columns}
            rowKey={(record) =>
              `${record.customerName}-${record.serviceTitle}-${record.stateBon}`
            }
            pagination={false}
            scroll={{ x: "max-content" }}
          />
        </Card>
      }
    </Wrapper>
  );
};

export default Reports;
