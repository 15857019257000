import { Alert, Button, Card, Col, Form, Input, Popconfirm, Row, Select, Switch, Table, message } from "antd";
import { Permissions, hasPermission } from "../../router/permissions";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import AdvantageClubCard from "../../components/advantageClubCard";
import { Link } from "react-router-dom";
import NoPicture from "../../assets/images/no-picture.jpg";
import Wrapper from "../../components/layout";
import moment from "moment-timezone";
import { showEmbededHTML } from "../../util/ckeditor";
import styled from "./styled.module.scss";
import { useAuth } from "../../providers/auth";
import { useRest } from "../../services/http";

const { Option } = Select;
const AdvantageClubList = () => {
    const { user } = useAuth();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [clientVisibleClubs, setClientVisibleClubs] = useState([]);
    const [employeeVisibleClubs, setEmployeeVisibleClubs] = useState([]);
    const [isFiltering, setIsFiltering] = useState(false);
    const [imageData, setImageData] = useState(null);


    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [tablePagination, setTablePagination] = useState({
        current: 1,
        pageSize: 15,
    });

    const {
        get: advantageClubList,
        data: allClubsData,
        status: allClubsStatus,
    } = useRest();

    const {
        remove: removeAdvantageClub,
        status: removeAdvantageClubStatus,
        message: removeAdvantageClubMessage,
    } = useRest();

    const {
        get: advantageClubCategoryList,
        data: categoriesData,
    } = useRest();

    const {
        get: getAdvantageClubsByCategories,
        data: getAdvantageClubsByCategoriesData,
        status: getAdvantageClubsByCategoriesStatus,
    } = useRest();

    const {
        get: getAdvantageClubsByCategoriesAndIsActive,
        data: getAdvantageClubsByCategoriesAndIsActiveData,
        status: getAdvantageClubsByCategoriesAndIsActiveStatus,
    } = useRest();


    const confirm = (id) => {
        removeAdvantageClub("removeAdvantageClub", { id: id });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await advantageClubList("advantageClubList", { page: tablePagination.current });
            } catch (error) {
                console.error("Erro ao obter a lista de categorias:", error);
            }
        };

        // Chama a função fetchData
        fetchData();

    }, []); // Adiciona advantageClubCategoryList como uma dependência para reexecutar o efeito quando ela mudar

    useEffect(() => {
        if (removeAdvantageClubStatus.success) {

            advantageClubList("advantageClubList", {
                page: tablePagination.current,
            });

            //update getAdvantageClubsByCategoriesAndIsActiveData
            const categoriesIds = categoriesData?.map((item) => item.id);
            const categoriesIdsString = categoriesIds.join(",");
            getAdvantageClubsByCategories("getAdvantageClubsByCategories", { id: categoriesIdsString });
            getAdvantageClubsByCategoriesAndIsActive("getAdvantageClubsByCategoriesAndIsActive", { id: categoriesIdsString });



            message.success(removeAdvantageClubMessage);
        }
        if (removeAdvantageClubStatus && removeAdvantageClubStatus.error) {
            message.error(removeAdvantageClubMessage);
        }
    }, [removeAdvantageClubStatus]);



    useEffect(() => {
        if (allClubsData && allClubsStatus.success) {
            setTablePagination({
                ...tablePagination,
                pageSize: allClubsData?.per_page,
                total: allClubsData?.total,
            });
        }
    }, [allClubsData]);


    const handleTableChange = (pagination) => {
        setTablePagination({ ...tablePagination, current: pagination.current });
    };


    const fetchCategories = async () => {
        await advantageClubCategoryList("advantageClubCategoryList", { page: 1 });
    };



    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        if (getAdvantageClubsByCategoriesStatus.success) {
            setEmployeeVisibleClubs(getAdvantageClubsByCategoriesData);
        }
    }, [getAdvantageClubsByCategoriesStatus]);

    useEffect(() => {
        if (getAdvantageClubsByCategoriesAndIsActiveStatus.success) {
            setClientVisibleClubs(getAdvantageClubsByCategoriesAndIsActiveData);
        }
    }, [getAdvantageClubsByCategoriesAndIsActiveStatus]);






    const columns = [
        {
            title: "Imagem",
            dataIndex: "image_attachment",
            key: "id",
            render: (image_attachment) => {



                return (
                    <img
                        // src={image_attachment ? `http://localhost:8000/${image_attachment.path}` : NoPicture}
                        src={image_attachment ? process.env.REACT_APP_API_URL_IMG + image_attachment.path : NoPicture}
                        alt="Imagem do Clube de Vantagens"
                        style={{ width: 50, height: 50 }}
                    />
                );
            },

        },
        {
            title: "Nome",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Status",
            dataIndex: "is_active",
            key: "is_active",
            render: (is_active) => {
                return is_active ? "Ativo" : "Inativo";
            },
        },
        {
            title: "Ações",
            dataIndex: "",
            render: (record) => (
                <>
                    {hasPermission(user, Permissions.cadastrar_clube_vantagens_caterogia) && (
                        <Link to={"/view-advantage-club/" + record.id} className={styled.icon}>
                            <i className="ri-edit-fill" title="Editar Vantagem" />
                        </Link>
                    )}

                    {hasPermission(user, Permissions.cadastrar_clube_vantagens_caterogia) && (
                        <Popconfirm
                            title={
                                "Tem certeza que deseja deletar a vantagem: " +
                                record.description
                            }
                            onConfirm={() => confirm(record.id)}
                            okText="Confirmar"
                            cancelText="Cancelar"
                        >
                            <a className={styled.icon}>
                                <i
                                    className={styled.iconDelete + " ri-delete-bin-fill"}
                                    title="Deletar Vantagem"
                                />
                            </a>
                        </Popconfirm>
                    )}
                </>
            ),
        },
    ];

    const handleFilter = async () => {
        try {
            await form.validateFields();
            const values = form.getFieldsValue();

            if (values?.categories?.length > 0) {
                const categoriesIds = values.categories.join(",");
                await getAdvantageClubsByCategories("getAdvantageClubsByCategories", { id: categoriesIds });
                await getAdvantageClubsByCategoriesAndIsActive("getAdvantageClubsByCategoriesAndIsActive", { id: categoriesIds });
                setIsFiltering(true);
            } else {
                const allCategoriesIds = categoriesData?.map((item) => item.id);
                const allCategoriesIdsString = allCategoriesIds.join(",");
                await getAdvantageClubsByCategories("getAdvantageClubsByCategories", { id: allCategoriesIdsString });
                await getAdvantageClubsByCategoriesAndIsActive("getAdvantageClubsByCategoriesAndIsActive", { id: allCategoriesIdsString });
                setIsFiltering(false);
            }
        } catch (error) {
            console.error("Erro ao filtrar clubes:", error);
        }
    };

    useEffect(() => {
        if (categoriesData) {
            const categoriesIds = categoriesData?.map((item) => item.id);
            const categoriesIdsString = categoriesIds.join(",");
            getAdvantageClubsByCategories("getAdvantageClubsByCategories", { id: categoriesIdsString });
            getAdvantageClubsByCategoriesAndIsActive("getAdvantageClubsByCategoriesAndIsActive", { id: categoriesIdsString });
        }

    }, [categoriesData]);


    useEffect(() => {
        // Set clientVisibleClubs based on employeeVisibleClubs, omitting inactive clubs
        const clientVisibleClubsFiltered = employeeVisibleClubs.filter((club) => club.is_active);
        setClientVisibleClubs(clientVisibleClubsFiltered);
    }, [employeeVisibleClubs]);



    return (
        <Wrapper>

            <Card style={{ marginBottom: 15,  borderRadius: 8 }}>
                <Form layout="vertical" autoComplete="off" form={form}>
                    <Row
                        justify="space-between"
                        align="center"
                        gutter={{ sm: 16, xs: 10 }}
                    >
                        <Col xs={24} sm={12}>
                            <Form.Item label="Categorias" name="categories">
                                <Select mode="multiple" placeholder="Selecione uma ou mais categorias">
                                    {/* Mapear categorias aqui */}
                                    {categoriesData?.map(category => <Option key={category.id} value={category.id}>{category.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Button
                                type="primary"
                                style={{ marginTop: 23, alignItems: "left" }}
                                onClick={() => handleFilter()}
                            >
                                Filtrar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>

            {
                user.isEmployee &&

                <Card style={{ marginBottom: 15,  borderRadius: 8 }}>
                    <Table
                        className={styled.table}
                        columns={columns}
                        rowKey={(record) => record?.id}
                        dataSource={isFiltering ? getAdvantageClubsByCategoriesAndIsActiveData : employeeVisibleClubs}
                        pagination={tablePagination}
                   
                        loading={loading}
                        onChange={handleTableChange}
                        scroll={{ x: 800 }}
                        size="small"
                    />
                </Card>
            } 

{!user.isEmployee && clientVisibleClubs && clientVisibleClubs.length > 0 && (
    <AdvantageClubCard data={clientVisibleClubs} />
)}
            



        </Wrapper>
    )
}
export default AdvantageClubList