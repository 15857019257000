import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Pagination,
  Popconfirm,
  Progress,
  Row,
  Select,
  Spin,
  Table,
  message,
} from "antd";
import { Permissions, hasPermission } from "../../router/permissions";
import { RiArrowRightLine, RiArrowRightSLine } from "react-icons/ri";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { DocumentText } from "iconsax-react";
import { Link } from "react-router-dom";
import { StatusType } from "./type";
import Wrapper from "../../components/layout";
import moment from "moment-timezone";
import styled from "./styled.module.scss";
import { useAuth } from "../../providers/auth";
import { useForm } from "antd/es/form/Form";
import { useRest } from "../../services/http";

const FlowList = () => {
  const [form] = useForm();
  const [filterParams, setFilterParams] = useState({
    customers: null,
    assignees: null,
    status: null,
    out_of_time: null,
  });
  const [clients, setClients] = useState();
  const [responsables, setResponsables] = useState();
  const [status, setStatus] = useState();

  const {
    get: flowsList,
    loading: flowListLoading,
    data: flowsData,
  } = useRest();

  const {
    get: flowsListUser,
    loading: flowUserListLoading,
    data: flowsUserData,
  } = useRest();

  const { user } = useAuth();

  const { get: getClient, data: dataClient, status: statusClient } = useRest();

  const {
    get: getResponsable,
    data: dataResponsable,
    status: statusResponsable,
  } = useRest();

  const {
    remove: removeFlow,
    status: statusRemoveFlow,
    message: messageRemoveFlow,
  } = useRest();

  const { get: getStatus, data: dataStatus, status: statusStatus } = useRest();

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  const { Panel } = Collapse;

  useEffect(() => {
    getFlow();
  }, [tablePagination?.current]);

  const getFlow = () => {
    const params = {
      page: tablePagination.current,
      customers: user?.customerID,
      assignees: filterParams.assignees,
      status: filterParams.status,
      out_of_time: filterParams.out_of_time,
    };

    const paramsUser = {
      page: tablePagination.current,
      id: user?.customerID,
    };

    if (user.isEmployee) {
      flowsList("flowsList", params);
      getClient("fullClientList", {});
    } else {
      flowsList("flowsList", params);
      getClient("fullClientList", {});
      flowsListUser("flowsListUser", paramsUser);
    }
  };

  useEffect(() => {
    if (!user.isEmployee && user?.customerID) {
      const params = {
        page: tablePagination.current,
        id: user?.customerID,
      };

      flowsListUser("flowsListUser", params);
    }
  }, [tablePagination.current, user?.customerID]);

  useEffect(() => {
    getResponsable("fullResponsableList", {});
    getStatus("statusFlow", {});
  }, []);

  useEffect(() => {
    if (statusClient?.success) {
      let clientArr = dataClient.map((item) => (
        <Select.Option key={item.user.id}>{item.user.name}</Select.Option>
      ));

      clientArr.sort((a, b) => {
        if (a.props.children < b.props.children) {
          return -1;
        }
        if (a.props.children > b.props.children) {
          return 1;
        }
        return 0;
      });
      setClients(clientArr);
    }
  }, [dataClient, statusClient]);

  useEffect(() => {
    if (statusStatus?.success) {
      let arr = Object.keys(dataStatus).map((item) => (
        <Select.Option key={item}>{dataStatus[item]}</Select.Option>
      ));

      setStatus(arr);
    }
  }, [dataStatus, statusStatus]);

  useEffect(() => {
    if (statusResponsable?.success) {
      let responsableArr = dataResponsable.map((item) => (
        <Select.Option key={item.id}>{item.description}</Select.Option>
      ));

      setResponsables(responsableArr);
    }
  }, [dataResponsable, statusResponsable]);

  const getResponsableById = (responsableId) => {
    const filter = dataResponsable?.filter(
      (responsable) => responsable.id === responsableId
    )[0];

    return filter ? filter.description : "-";
  };

  const paginationChange = (current) => {
    setTablePagination({ ...tablePagination, current: current });
  };

  const deleteFlow = (customerId, serviceId, stateBon) => {
    removeFlow("removeFlow", { customerId, serviceId, stateBon });
  };

  useEffect(() => {
    if (statusRemoveFlow?.success) {
      message.success(messageRemoveFlow);
      getFlow();
    } else if (statusRemoveFlow?.error) {
      message.error(messageRemoveFlow);
    }
  }, [statusRemoveFlow]);

  const canDeleteFlow = (steps) => {
    if (!Array.isArray(steps) || steps.length === 0) {
      return false;
    }
    return steps.every((step) => step.status === "NOT");
  };

  const getServiceTitleWithState = (service, allServices) => {
    const isDuplicated =
      allServices.filter((s) => s.title === service.title).length > 0;

    // Retorna o título com o estado caso haja duplicatas
    return isDuplicated
      ? `${service.title} (${service.state_bon})`
      : service.title;
  };

  const columns = [
    {
      title: "Etapa",
      dataIndex: "title", // Campo "title" mapeado do backend
      key: "title",
      align: "center",
    },
    {
      title: "Responsável",
      key: "assignee",
      align: "center",
      render: (record) => (
        <span>{record.assignee?.name || "-"}</span> // Assignee acessado corretamente
      ),
    },
    {
      title: "Data de Início",
      key: "start_date",
      align: "center",
      render: (record) => (
        <span>
          {record.start_date
            ? moment(record.start_date)
                .tz("America/Sao_Paulo")
                .format("DD/MM/YYYY")
            : "-"}
        </span>
      ),
    },
    {
      title: "Data de Conclusão",
      key: "end_date",
      align: "center",
      render: (record) => (
        <span>
          {record.end_date
            ? moment(record.end_date)
                .tz("America/Sao_Paulo")
                .format("DD/MM/YYYY")
            : "-"}
        </span>
      ),
    },
    {
      title: "Status",
      key: "status",
      align: "center",
      render: (record) => (
        <span className={record.status === "FIN" ? styled.finish : ""}>
          {StatusType[record.status] || record.status} {/* Mapeia o status */}
        </span>
      ),
    },
    {
      title: "Ações",
      key: "actions",
      align: "center",
      render: (record) => (
        <Link to={`/interaction/${record.id}`}>
          <Button icon={<RiArrowRightLine className="remix-icon" />}>
            Acompanhar
          </Button>
        </Link>
      ),
    },
  ];

  const columnsCustomer = [
    {
      title: "Etapa",
      dataIndex: "title", // Mapeia o título da etapa corretamente
      key: "title",
      align: "center",
    },
    {
      title: "Responsável",
      key: "assignee_id",
      align: "center",
      render: (record) => (
        <span>{record.assignee?.name}</span> // Acesse a chave correta dentro de "flow"
      ),
    },
    {
      title: "Data de Início",
      key: "start_date",
      align: "center",
      render: (record) => (
        <span>
          {record.start_date
            ? moment(record.start_date)
                .tz("America/Sao_Paulo")
                .format("DD/MM/YYYY")
            : "-"}
        </span>
      ),
    },
    {
      title: "Data de Conclusão",
      key: "end_date",
      align: "center",
      render: (record) => (
        <span>
          {record.end_date
            ? moment(record?.end_date)
                .tz("America/Sao_Paulo")
                .format("DD/MM/YYYY")
            : "-"}
        </span>
      ),
    },
    {
      title: "Status",
      key: "status",
      align: "center",
      render: (record) => (
        <span className={record.status === "FIN" ? styled.finish : ""}>
          {StatusType[record.status]}
        </span>
      ),
    },
    {
      title: "Ações",
      key: "acoes",
      align: "center",
      render: (record) => {
        if (!user?.isEmployee && record?.status === "NOT") {
          return null;
        }

        return (
          <Link to={`/interaction-customer/${record.id}`}>
            <Button icon={<RiArrowRightLine className="remix-icon" />}>
              Acompanhar
            </Button>
          </Link>
        );
      },
    },
  ];

  const filter = () => {
    flowsList("flowsList", {
      page: 1,
      customers: filterParams.customers,
      assignees: filterParams.assignees,
      status: filterParams.status,
      out_of_time: filterParams.out_of_time,
    });

    setTablePagination({
      ...tablePagination,
      current: 1,
    });
  };

  const resetFilter = () => {
    const params = {
      customers: null,
      assignees: null,
      status: null,
      out_of_time: null,
    };

    setFilterParams(params);
    setTablePagination({
      ...tablePagination,
      current: 1,
    });
    form.resetFields();
    flowsList("flowsList", {
      page: 1,
      ...params,
    });
  };

  function sortSteps(steps) {
    if (Array.isArray(steps)) {
      const sortedSteps = steps.sort(
        (a, b) => (a.sort_order || 0) - (b.sort_order || 0)
      );
      return sortedSteps;
    } else {
      return [];
    }
  }

  const view = user.isEmployee ? (
    <>
      {user.isEmployee && (
        <Card className={styled.card}>
          <Form layout="vertical" autoComplete="off" form={form}>
            <Row
              justify="space-between"
              align="center"
              gutter={{ sm: 16, xs: 10 }}
            >
              <Col xs={24} sm={7}>
                <Form.Item label="Clientes" name="clientes">
                  <Select
                    mode="multiple"
                    showSearch
                    size="small"
                    allowClear
                    placeholder="Selecionar Clientes"
                    optionFilterProp="children"
                    onChange={(value) =>
                      setFilterParams({ ...filterParams, customers: value })
                    }
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {clients}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={7}>
                <Form.Item label="Responsáveis" name="responsaveis">
                  <Select
                    mode="multiple"
                    showSearch
                    size="small"
                    allowClear
                    placeholder="Selecionar Responsáveis"
                    optionFilterProp="children"
                    onChange={(value) =>
                      setFilterParams({ ...filterParams, assignees: value })
                    }
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {responsables}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={7}>
                <Form.Item label="Status" name="status">
                  <Select
                    mode="multiple"
                    size="small"
                    showSearch
                    allowClear
                    placeholder="Selecionar Status"
                    optionFilterProp="children"
                    onChange={(value) =>
                      setFilterParams({ ...filterParams, status: value })
                    }
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {status}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={3}>
                <Button
                  className={styled.button}
                  type="primary"
                  htmlType="submit"
                  onClick={() => filter()}
                >
                  Buscar
                </Button>
                <br />
                <a href="#" onClick={() => resetFilter()}>
                  Limpar busca
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={7}>
                <Checkbox
                  className={styled.checkbox}
                  checked={filterParams?.out_of_time}
                  onChange={(e) =>
                    setFilterParams({
                      ...filterParams,
                      out_of_time: e.target.checked,
                    })
                  }
                >
                  Etapa fora do prazo
                </Checkbox>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
      {flowsData?.data?.customers?.map((customer) => (
        <div key={customer.id}>
          <Card className={styled.card} size="small">
            <p className={styled.client}>
              <span>{customer.name}</span>
            </p>
            <Collapse style={{ marginBottom: "10px" }}>
              {customer?.service.map((service) => (
                <Panel
                  collapsible="header"
                  header={
                    <div className={styled.wrap}>
                      <DocumentText size={18} />
                      <div style={{ minWidth: "300px" }}>
                        <p className="hp-mb-0 hp-p1-body">
                          {getServiceTitleWithState(service, customer?.service)}
                        </p>
                        <Progress
                          percent={Math.floor(service?.percentual_finished)}
                        />
                      </div>
                    </div>
                  }
                  showArrow={false}
                  extra={
                    <>
                      <>
                        {canDeleteFlow(service?.steps) &&
                          hasPermission(user, Permissions.remover_fluxos) && (
                            <Popconfirm
                              title={
                                "Tem certeza que deseja deletar o serviço: " +
                                service.title +
                                "?"
                              }
                              onConfirm={() =>
                                deleteFlow(
                                  customer?.id,
                                  service?.id,
                                  service?.state_bon
                                )
                              }
                              okText="Confirmar"
                              cancelText="Cancelar"
                            >
                              <a className={styled.icon}>
                                <i
                                  className={
                                    styled.iconDelete + " ri-delete-bin-fill"
                                  }
                                  title="Deletar serviço"
                                />
                              </a>
                            </Popconfirm>
                          )}

                        <RiArrowRightSLine
                          size={24}
                          className="hp-collapse-arrow hp-text-color-black-60"
                        />
                      </>
                    </>
                  }
                >
                  <Table
                    className={styled.table}
                    columns={columns}
                    rowKey={(record) => record?.id}
                    dataSource={sortSteps(service.steps)}
                    loading={flowListLoading}
                    scroll={{ x: 800 }}
                    pagination={false}
                    size="small"
                  />
                </Panel>
              ))}
            </Collapse>
          </Card>
        </div>
      ))}
      {flowsData?.last_page !== 1 && (
        <Pagination
          defaultCurrent={tablePagination.current}
          defaultPageSize={tablePagination.pageSize}
          total={flowsData?.total}
          onChange={paginationChange}
        />
      )}
    </>
  ) : (
    <>
      <Card className={styled.card}>
        <h2>Meus serviços</h2>
        {flowsUserData?.data?.customers?.length === 0 && (
          <h5>Ainda não há serviços vinculados à sua conta.</h5>
        )}
        {flowsUserData?.data?.customers?.map((flow) => {
          const allServices = flowsUserData?.data?.customers?.map(
            (f) => f.services
          );
          return (
            <Collapse key={flow.id} style={{ marginBottom: "10px" }}>
              <Panel
                header={
                  <div className={styled.wrap}>
                    <DocumentText size={18} />
                    <div style={{ minWidth: "300px" }}>
                      <p className="hp-mb-0 hp-p1-body">
                        {getServiceTitleWithState(flow.services, allServices)}
                      </p>
                      <Progress
                        percent={Math.floor(
                          flow?.services?.percentual_finished
                        )}
                      />
                    </div>
                  </div>
                }
                showArrow={false}
                extra={
                  <RiArrowRightSLine
                    size={24}
                    className="hp-collapse-arrow hp-text-color-black-60"
                  />
                }
              >
                <Table
                  className={styled.table}
                  columns={columnsCustomer}
                  key={flow.id}
                  rowKey={(record) => record?.id}
                  dataSource={(flow.services?.steps || []).sort(
                    (a, b) => a.sort_order - b.sort_order
                  )}
                  loading={flowListLoading}
                  scroll={{ x: 800 }}
                  pagination={false}
                  size="small"
                />
              </Panel>
            </Collapse>
          );
        })}
      </Card>
    </>
  );
  return (
    <Wrapper>
      {flowListLoading || flowUserListLoading ? (
        <Row type="flex" align="middle" justify="center">
          <Col>
            <Spin />
          </Col>
        </Row>
      ) : (
        view
      )}
    </Wrapper>
  );
};
export default FlowList;
