/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Card, Col, Row, Spin, Steps, message } from "antd";
import { useEffect, useState } from "react";

import Address from "./address";
import ComplementData from "./complementData";
import Coren from "./coren";
import ListEducation from "./education/listEducation";
import ListEmployer from "./employer/listEmployer";
import ListPortals from "./security/listPortal";
import PersonalDataPage from "./personalData";
import Wrapper from "../../components/layout";
import { useParams } from "react-router-dom";
import { useRest } from "../../services/http";

const { Step } = Steps;

const ViewClient = (props) => {
  const params = useParams();
  const { id } = props.id ? props : params;
  const { documents } = props;
  const [current, setCurrent] = useState(0);
  const [createClient, setCreateClient] = useState({});
  const [personalData, setPersonalData] = useState({});
  const [addressData, setAddressData] = useState({});
  const [complementData, setComplementData] = useState({});
  const [corenData, setCorenData] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const { get: viewCustomer, data: viewCustomerData } = useRest();

  useEffect(() => {
    viewCustomer("viewCustomer", { id });
  }, [id]);

  useEffect(() => {
    setCreateClient(viewCustomerData);
  }, [viewCustomerData]);
  const {
    put: updateCustomer,
    data: customerUpdateData,
    status: customerUpdateStatus,
    message: customerUpdateMessage,
    errors: customerUpdateError,
  } = useRest();
  
  useEffect(() => {


    if (customerUpdateStatus.error) {
  
      if (customerUpdateStatus.error && customerUpdateData) {

        // Transformar os erros do campo `data` em um array utilizável
        const formattedErrors = Object.keys(customerUpdateData).map(
          (key) => {
            return `${key}: ${customerUpdateData[key]}`;
          }
        );

        setErrorData(formattedErrors);

      } else {
        console.log("Erro sem campo `data` encontrado:", customerUpdateError);
      }
    } else if (customerUpdateStatus.success) {
      // Limpar os erros quando a atualização for bem-sucedida
      setErrorData([]);
      message.success("Cliente atualizado com sucesso!");
      setCreateClient(customerUpdateData?.data);
    }
  }, [customerUpdateError, customerUpdateStatus, customerUpdateData]);
  
  const steps = [
    {
      title: "Dados Pessoais",
      content: (
        <PersonalDataPage
          setData={(value) => {
            setPersonalData(value);
          }}
          defaultData={createClient}
          isEditable={isEditable}
        />
      ),
    },
    {
      title: "Endereço",
      content: (
        <Address
          setData={(value) => setAddressData(value)}
          defaultData={createClient}
          isEditable={isEditable}
        />
      ),
    },
    {
      title: "Dados Complementares",
      content: (
        <ComplementData
          setData={(value) => setComplementData(value)}
          defaultData={createClient}
          isEditable={isEditable}
        />
      ),
    },
    {
      title: "Dados Licença COREN",
      content: (
        <Coren
          setData={(value) => setCorenData(value)}
          defaultData={createClient}
          isEditable={isEditable}
        />
      ),
    },
    {
      title: "Dados de Segurança",
      content: <ListPortals />,
    },
    {
      title: "Dados de Ensino",
      content: <ListEducation />,
    },
    {
      title: "Dados de Empregador",
      content: <ListEmployer />,
    },
  ];

  if (documents) {
    steps.push({
      title: "Meus documentos",
      content: documents,
    });
  }

  const onChange = (value) => {
    setCurrent(value);
    setIsEditable(false);
  };

  const finish = () => {
    setIsEditable(false);
    if (current <= 3) {
      updateCustomer(
        "updateCustomer",
        { id: id },
        {
          ...createClient,
          ...personalData,
          ...addressData,
          ...complementData,
          ...corenData,
        }
      );
    }
  };

  return (
    <Wrapper>
      {!createClient ? (
        <Row type="flex" align="middle" justify="center">
          <Col>
            <Spin />
          </Col>
        </Row>
      ) : (
        <>
          <Steps
            current={current}
            onChange={onChange}
            labelPlacement="vertical"
            size="small"
            style={{ marginBottom: "25px" }}
          >
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <Card>
            <div className="steps-content">
            {customerUpdateStatus.error && (
  <Alert
    message="Erro ao atualizar os dados"
    description={
      <ul>
        {errorData.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    }
    type="error"
    showIcon
  />
)}


              {steps[current].content}
            </div>
            <div className="steps-action">
              {isEditable && (
                <Button
                  type="primary"
                  style={{
                    margin: "0 8px 0 0",
                  }}
                  onClick={() => setIsEditable(false)}
                >
                  Cancelar
                </Button>
              )}
              {!isEditable && current <= 3 && (
                <Button
                  type="primary"
                  onClick={() => setIsEditable(true)}
                >
                  Editar
                </Button>
              )}
              {isEditable && current <= 3 && (
                <Button type="primary" onClick={finish}>
                  Salvar e enviar
                </Button>
              )}
            </div>
          </Card>
        </>
      )}
    </Wrapper>
  );
};

export default ViewClient;
